@charset "UTF-8";
@font-face {
  font-family: Arial;
  src: url("../fonts/Arial.ttf");
}
@font-face {
  font-family: Arial Black;
  src: url("../fonts/Arial Black.ttf");
}
@font-face {
  font-family: Arial Bold;
  src: url("../fonts/Arial Bold.ttf");
}
@font-face {
  font-family: Arial Italic;
  src: url("../fonts/Arial Italic.ttf");
}
/*$family-primary: "Arial-UCH", sans-serif;*/


#content-trustees {
  background-image: url("../img/background-trustees.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

#content-voters {
  background-image: url("../img/parallax05.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

#content-not-allowed {
  background-image: url("../img/background-not-allowed.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

#content-results {
  background-image: url("../img/background-results.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

#content-tallied {
  background-image: url("../img/background-closed.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.voters-section {
  background-color: rgba(255, 255, 255, 0.85);
}

.voters-info-box {
  background-color: white;
  border: 2px solid #004c94;
  border-radius: 5px;
  padding: 1rem;
}

.search-box {
  background-color: white;
  border: 2px solid #004c94;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.search_box {
  justify-content: space-between;
  padding: 0;
}

.input_search {
  font-size: 14px;
  font-family: monospace, serif;
  width: 100%;
  color: black;
  border: 0;
  outline: none;
}

.font-caption{
  font-weight: 700 !important;
  font-size: 16px;
}

.search-button {
  cursor: pointer;
  color: #004c94;
}

.voters-table {
  margin-top: 2rem;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0) !important;
}

.voters-table td {
  border: 1px solid #004c94 !important;
}

.voters-table thead th {
  background-color: #004c94;
  color: white !important;
  border: 1px solid #004c94 !important;
}

#results-section {
  background-color: rgba(255, 255, 255, 0.85);
}

#question-box-results {
  background-color: white;
  border: 1px solid #004c94;
}

#results-table td {
  border: 1px solid #004c94 !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

#trustee-box {
  background-color: transparent;
  border: 1px solid white !important;
  color: white;
}

#trustees-link {
  color: #00b6fe;
}

#not-results-box {
  border: 1px solid #004c94 !important;
  color: #004c94;
}

#election-section {
  background-color: rgba(0, 76, 148, 0.8);
}

/* Loading */
#loading-section {
  background-color: rgba(0, 76, 148, 0.15);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#loading-top-wrapper {
  align-self: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-img-wrapper {
  align-self: auto;
  margin-top: auto;
}

#loading-final-img {
  width: 20rem;
}

/* Header & Navbar */
.parallax {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-01 {
  background-image: url("../img/parallax01.jpg");
}

.parallax-02 {
  background-image: url("../img/parallax02.jpg");
}

.parallax-03 {
  background-image: url("../img/parallax03.jpg");
}

.parallax-04 {
  background-image: url("../img/parallax04.jpg");
}

.parallax-05 {
  background-image: url("../img/parallax05.jpg");
}

.header-hero {
  padding-bottom: 1.5rem !important;
}

#logo-header {
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  max-height: 6rem;
}

#navbar {
  background-color: transparent;
}

#navbar-buttons {
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 4.9rem;
  display: flex;
  align-items: flex-end;
}

.navbar-button {
  background-color: white;
  border: none;
  margin-right: 0.8rem;
  padding-left: 0;
  width: 4rem;
  text-align: left;
  cursor: pointer;
  padding-bottom: 0.2rem;
  text-decoration: none !important;
}

.menu-text-admin {
  text-decoration: none;
  color: #004c94;
  text-decoration: none !important;
  font-weight: 600 !important;
}

.menu-text-admin:hover {
  color: #6dcaf3;
  cursor: pointer;
}

#text-button {
  text-align: left !important;
  margin-right: 15px;
  width: 60px;
  position: relative;
  text-decoration: none;
  font-size: 0.85rem;
}

#text-button:after {
  content: "";
  position: absolute;
  background: #6dcaf3;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  transition: 0.3s;
}

#text-button:hover:after {
  width: 50%;
  background: #004c94;
  transition: 0.3s;
}

/* Progress Bar */
.line {
  top: 19.97rem;
  background: #004c94;
  position: absolute;
  height: 2px;
  width: 100%;
}

.progress-box {
  box-shadow: none !important;
  border-radius: 5px !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-size: 1.4em;
}

#progress-bar {
  background-color: white;
  padding-top: 0;
  padding-bottom: 0;
}

.progress-container {
  padding-left: 2rem;
  padding-right: 2rem;
}

.progress-icon {
  vertical-align: middle;
  height: 2rem !important;
  width: 2rem !important;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.progress-current {
  background-color: #e43427 !important;
  box-shadow: 0 0 0 10px white, 0 0 0 12px #e43427 !important;
}

.progress-next {
  background-color: #b3c9de !important;
}

.progress-previous {
  background-color: #e43427 !important;
}

.progress-previous:hover {
  background-color: #e75a50 !important;
}

.previous-button {
  background-color: #004c94 !important;
}

#election-title-background-admin {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 0.5em;
}

.progress-bar-items {
  margin-bottom: 0 !important;
}

/* 01: Instructions Middle */
#election-title {
  color: #004c94 !important;
  font-weight: 600 !important;
}

.buttons-container {
  align-self: start;
}

#instruction-main-box {
  background-color: transparent;
  box-shadow: none;
}

#instructions-main-image {
  max-width: 7rem;
  margin-left: -1.7rem;
  margin-top: -1rem;
}

#instructions-main-paragraph {
  text-align: justify;
}

.img-wrapper {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: -3rem;
}

#instructions-text-img {
  width: 15rem;
  display: inline-block;
}

#info-media {
  background-color: rgba(0, 76, 148, 0.15);
}

/* 01: Instructions Steps */
.steps-container {
  padding-left: 2rem;
  padding-right: 2rem;
}

#instructions {
  background-color: rgba(228, 52, 39, 0.15);
}

.instruction-image {
  border-radius: 15px;
  object-fit: cover;
}

#select-image {
  border: 1px solid #004c94;
}

#review-image {
  border: 1px solid #fdd245;
}

#send-image {
  border: 1px solid #e43427;
}

.image.step-figure {
  padding-top: 39%;
}

.image.step-figure img {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.step-text {
  text-align: justify;
}

#start-button {
  background-color: #e43427;
  border-radius: 10px;
  color: white;
}

/* 02: Select Question */
#question-section {
  background-color: rgba(0, 76, 148, 0.15);
}

.question-box {
  background-color: #004c94 !important;
  line-height: 3.5rem;
}

.options-box {
  margin-left: 7rem;
  margin-right: 7rem;
  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
}

input[type="radio"] {
  width: 17px;
  height: 17px;
}

.question-button {
  width: 12rem;
  background-color: #027d47 !important;
  color: white !important;
  border-radius: 10px !important;
}

#select-final-img {
  width: 10rem;
  display: inline-block;
}

/* 03: Review */
#review-section {
  background-color: rgba(2, 125, 71, 0.1);
}

.review-answer {
  background-color: #004c94 !important;
}

.review-buttons {
  border-radius: 10px !important;
}

.review-buttons:hover {
  background-color: #00b7ff !important;
}

#audit-button {
  background-color: rgba(0, 76, 148, 0.15);
  color: #004c94 !important;
}

#proceed_button {
  background-color: #e43427 !important;
  color: white !important;
}

#review-final-img {
  width: 15rem;
  display: inline-block;
  margin-bottom: -4rem;
}

/* Encrypting */
#encrypting-section {
  background-color: rgba(255, 204, 51, 0.15);
  height: 50rem;
}

#encrypting-final-img {
  width: 15rem;
  display: inline-block;
  margin-bottom: -4rem;
}

#search-message {
  margin-left: 30px;
  color: #e43427;
  font-size: 18px;
  font-weight: bold;
  padding-top: 0.3rem;
  text-align: center;
}

.pagination-next {
  background-color: #004c94;
  font-weight: bold;
}

.pagination-previous {
  background-color: #004c94;
  font-weight: bold;
}

/* 04: Audit */
#audit-section {
  background-color: rgba(2, 125, 71, 0.1);
  min-height: 50rem;
}

#audit-vote-tab {
  background-color: #004c94;
  color: white;
}

#audit-vote-wrapper {
  margin-right: 1rem;
}

#important-tab {
  background-color: #027d47;
  color: white;
}

#pane-1 {
  border: 15px solid #004c94;
  border-top-left-radius: 0;
}

.audit-trail-box {
  text-align: left;
  word-break: break-all;
  max-height: 30rem;
  overflow-y: scroll;
}

#pane-2 {
  border: 15px solid #027d47;
  border-top-left-radius: 0;
}

.important-box {
  text-align: left;
  min-height: 30rem;
}

#back-to-vote {
  border-radius: 10px !important;
  color: white;
  background-color: #004c94 !important;
}

#send-audited-vote {
  border-radius: 10px !important;
  color: white;
  background-color: #027d47 !important;
}

#audit-final-img {
  width: 10rem;
  display: inline-block;
  margin-bottom: -4rem;
}

#not-allowed-section {
  background-color: rgba(255, 255, 255, 0.85);
}

#vote-fingerprint-anchor::before {
  word-break: normal;
}

/* 05: Send */
#send-section {
  background-color: rgba(255, 255, 255, 0.85);
}

.send-text {
  line-height: 1.8 !important;
}

#vote-code {
  background-color: #00b7ff;
  margin-left: 6rem;
  margin-right: 6rem;
}
@media screen and (max-width: 768px) {
  #vote-code {
    margin-left: auto;
    margin-right: auto;
  }
}

#back-button {
  background-color: #004c94 !important;
  border-color: #004c94;
  color: white !important;
  border-radius: 10px !important;
}

#back-button:hover {
  background-color: #00b7ff !important;
  border-color: #00b7ff !important;
}

.send-img-wrapper {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

#send-final-img {
  width: 20rem;
}

#tallied-img {
  width: 14rem;
}

#closed-election-text {
  color: #e43427;
}

/* Trustees Site */
.steps-title {
  font-family: "Arial Black", sans-serif;
}

#medium-section {
  background-color: rgba(0, 76, 148, 0.6) !important;
}

#keygen-section {
  background-color: rgba(0, 76, 148, 0.6) !important;
}

.step-button {
	color: #004c94cc !important;
	border: 2px solid white !important;
	border-radius: 5px !important;
	justify-content: center !important;
  text-transform: uppercase;
}

.step-button:hover {
	color: white !important;
	background-color: #004c94 !important;
}

.inactive-button {
  background-color: rgba(83, 83, 83, 0.8) !important;
  color: #c9bebe !important;
  border: 2px solid #c9bebe !important;
  cursor: not-allowed !important;
}

.keygen-button {
  color: white !important;
  background-color: #004c94 !important;
  font-weight: bold;
}

.verify-pk {
  border: 2px solid white !important;
  color: white !important;
  background-color: #004c94 !important;
}

#decryption-wait {
  margin-top: 2rem;
  background-color: #ffedb44d;
  border-radius: 10px;
  color: #004c94;
  padding: 1rem;
  border: 5px solid #fdd245;
}

#decryption-finish {
  margin-top: 2rem;
  background-color: #a7dd914d;
  border-radius: 10px;
  color: #004c94;
  padding: 1rem;
  border: 5px solid #2d9e00;
}

.loading-trustee {
  height: 3rem;
  width: 3rem;
}

#icon-right {
  cursor: default;
  border-color: white;
}

#icon-right:hover {
  z-index: auto;
}

#drawing-section {
  background-color: rgba(255, 255, 255, 0.85);
}

.do-tally {
  margin-left: auto;
  margin-right: auto;
  color: white !important;
  border: 2px solid white !important;
  background-color: #004c94 !important;
}

#help-desk-list {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

/* Footer */
.footer-container {
  word-wrap: break-word;
}

.bottom-hero {
  padding-bottom: 2.5rem !important;
}

.footer {
  background-color: #004c94 !important;
  padding: 0.5rem !important;
  color: white;
}

.confirm-alert {
  min-height: 100%;
  min-width: 100%;
  background-color: white;
  padding: 50px;
  border-radius: 30px;
  box-shadow: 0 0 10px 5px;
}

.alert-buttons:hover {
  cursor: pointer;
}

.btn-fixed {
  width: 150px;
}

.btn-fixed-mobile {
  @media screen and (min-width: 768px) {
    width: 150px;
  }
}

.button-custom {
  background-color: #004c94 !important;
  color: white !important;
  font-weight: bold;
}

.button-custom:hover {
  background-color: #00b7ff !important;
}

/*# sourceMappingURL=booth.css.map */

.create-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.chart-container {
  width: 60vw;
}

.home-container{
  width: 60vw;
}

.canvas-graph {
  width: 80%;
}

.urna-voter-hash{
  font-family: monospace;
}

@media screen and (max-width: 992px) {
  .chart-container {
    width: 80vw;
  }
  .home-container{
    width: 120vw;
  }
  .canvas-graph {
    width: 250%;
  }
  .urna-table {
    width: 88%;
  }
  .urna-voter-hash {
    font-size: 10px;
  }
}

canvas {
  height: 100% !important;
}

@keyframes logs {
  from {
    left: 1000px;
  }

  to {
    left: 0px;
  }
}

.logs-box {
  position: relative;
  width: 73vw !important;
  animation-name: logs;
  animation-duration: 0.5s;
}

@media screen and (min-width: 992px) {
  .logs-box {
    max-width: 50%;
  }
}


/* ranking-question-dev */

.ranking__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 5%;
}

.ranked__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.ranking__idx_col {
  display: flex;
  flex-flow: column nowrap;
  width: 10%;
}

.ranking__opt_col {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-left: .5em;
}

.ranking__opt_lake {
  padding: 0.2em;
  margin-top: 5%;
}

.ranking__btn {
  border: #84C7EF solid 2px;
  background: white;
  color: black;
  border-radius: 1em;
  padding: 1em;
  touch-action: none;
}

.ranking__idx {
  padding-bottom: 1em;
  padding-top: 1em;
}

.ranked {
  border: 2px;
  font-weight: 700;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: .5em;
  margin-bottom: .5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.2em;
}

.rankable {
  border: 2px;
  font-weight: 700;
  padding-top: 1em;
  padding-bottom: 1em;
  margin: .3em;
  display: flex;
}

.ranking-secret-message {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  text-align: center;
  align-items: center;
}

.circled-icon {
  padding: 0.1em;
  border: 0.15em solid black;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  aspect-ratio: 1/1;
  height: 100%;
  padding-bottom: 1em;
}