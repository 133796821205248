@charset "utf-8";

@import "bulma/bulma.sass";

// Import Bulma first
@import "bulma";
// Import Component main Sass File
@import "~@creativebulma/bulma-collapsible";

@font-face {
  font-family: Arial;
  src: url("../fonts/Arial.ttf");
}

@font-face {
  font-family: Arial Black;
  src: url("../fonts/Arial Black.ttf");
}

@font-face {
  font-family: Arial Bold;
  src: url("../fonts/Arial Bold.ttf");
}

@font-face {
  font-family: Arial Italic;
  src: url("../fonts/Arial Italic.ttf");
}

a.nounderline {text-decoration: none; }

.castdone-box {
  display: flex;
  flex-direction: column;
}

$family-primary: "Arial", sans-serif;
$red-uch: #e43427;
$blue-uch: #004c94;
$green-uch: #027d47;
$yellow-uch: #fdd245;
$fullhd: 1200px;
$widescreen: 992px;
$desktop: 992px;

.columns:last-child {
  margin-bottom: 0 !important;
}

/* Preload all images */
div#preload {
  display: none;
}

/* Loading View */
#loading-section {
  background-color: rgba(0, 76, 148, 0.15);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-column {
  margin-top: auto;
  margin-bottom: auto;
}

#loading-top-wrapper {
  align-self: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-img-wrapper {
  align-self: auto;
  margin-top: auto;
}

#loading-final-img {
  width: 18vw;
}

/* Background image on all views */
#content {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.parallax-01 {
  background-image: url("../img/parallax01.jpg") !important;
}

.parallax-02 {
  background-image: url("../img/parallax02.jpg") !important;
}

.parallax-03 {
  background-image: url("../img/parallax03.jpg") !important;
}

.parallax-04 {
  background-image: url("../img/parallax04.jpg") !important;
}

/* Header & Navbar */
#header-section {
}

.header-hero {
  padding-bottom: 1.5rem !important;
}

#logo-header {
  background-color: white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  height: 4.7rem;
  padding-top: 0.5rem;
  max-height: 6rem;
  margin-top: -0.4rem;
}

#navbar {
  background-color: transparent;
}

.buttons-container {
  align-self: start;
}

#navbar-buttons {
  background-color: white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 4.9rem;
  display: flex;
  align-items: flex-end;
}

.navbar-button {
  background-color: white;
  border: none;
  margin-right: 0.8rem;
  padding-left: 0;
  width: 4rem;
  text-align: left;
  cursor: pointer;
  padding-bottom: 0.2rem;
}

.menu-text {
  color: black;
  font-size: 0.8rem;
}

#navbar-button-1 {
  border-bottom: 2px solid $blue-uch;
}

#inicio:hover {
  color: $blue-uch !important;
  font-weight: bold;
}

#navbar-button-2 {
  border-bottom: 2px solid $red-uch;
}

#info:hover {
  color: $red-uch !important;
  font-weight: bold;
}

#navbar-button-3 {
  border-bottom: 2px solid $green-uch;
}

#ayuda:hover {
  color: $green-uch !important;
  font-weight: bold;
}

#navbar-button-4 {
  border-bottom: 2px solid $yellow-uch;
}

#salir:hover {
  color: $yellow-uch !important;
  font-weight: bold;
}

#election-title-background {
  background-color: rgba(255, 255, 255, 0.85);
}

#election-title {
  color: $blue-uch !important;
  font-weight: 600 !important;
}

$navbar-item-img-max-height: 3.5rem;

/* Progress Bar */
.line-1 {
  top: 17.6rem; // TODO: Review this (try not to put measures so specific)
  background: $blue-uch;
  position: absolute;
  height: 2px;
  width: 100%;
}

.progress-box {
  box-shadow: none !important;
  border-radius: 5px !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-size: 1.4em;
}

#progress-bar {
  background-color: white;
  padding-top: 0;
  padding-bottom: 0;
}

.progress-container {
  padding-left: 2rem;
  padding-right: 2rem;
}

.progress-icon {
  vertical-align: middle;
  height: 2rem !important;
  width: 2rem !important;
}

.progress-current {
  background-color: $red-uch !important;
  box-shadow: 0 0 0 10px white, 0 0 0 12px $red-uch !important;
}

.progress-next {
  background-color: #b3c9de !important;
}

.progress-previous {
  background-color: $red-uch !important;
}

.progress-bar-items {
  --columnGap: 1.8rem !important;
  margin-top: 0 !important;
  width: 70%;
}

.hide-mobile {
	@media screen and (max-width: 768px) {
		display: none !important;
	}
}

/* 01: Instructions Middle */
#instruction-main-box {
  background-color: transparent;
  box-shadow: none;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

#instructions-main-image {
  max-width: 7rem;
  margin-left: -1.7rem;
  margin-top: -1rem;
}

#instructions-main-paragraph {
  text-align: justify;
  font-size: 17px;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    text-align: justify;
  }
}

.img-wrapper {
  //display: flex !important;
  //align-items: center;
  //justify-content: center;
  //margin-bottom: -3rem;
}

#instructions-text-img {
  width: 15rem;
  //display: inline-block;
}

#info-media {
  background-color: #d9e4ef;
}

/* 01: Instructions & Steps */
.card-header-icon {
  padding-left: 0 !important;
  padding-right: 0rem !important;
}

.step-icon {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.card-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.steps-container {
  padding-left: 2rem;
  padding-right: 2rem;
}

#instructions {
  background-color: rgba(255, 255, 255, 0.85);
  min-height: 33rem;
}

.instruction-image {
  border-radius: 15px;
  object-fit: cover;
}

#select-image {
  border: 1px solid $blue-uch;
}

#review-image {
  border: 1px solid $blue-uch;
}

#send-image {
  border: 1px solid $blue-uch;
}

.image.step-figure {
  padding-top: 39%;
}

.image.step-figure img {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

$card-background-color: transparent;
$card-shadow: 0;
$card-header-shadow: 0;

.step-text {
  text-align: left;
  font-size: 17px;
}

#start-button {
  background-color: $blue-uch;
  border-color: $blue-uch;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}

#start-button:hover {
  background-color: #00b7ff;
  border-color: #00b7ff;
}

/* 02: Select Question */
#question-section {
  background-color: rgba(255, 255, 255, 0.85);
}

#auth-section{
	background-color: rgba(255, 255, 255, 0.85);
	min-height: 8rem;
}

.question-box {
  background-color: $blue-uch !important;
  line-height: 1rem;
  margin-left: 7rem;
  margin-right: 7rem;
  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.control-box {
  width: 90%;
}

input[type="radio"] {
  width: 17px;
  height: 17px;
}

.question-button {
  width: 12rem;
  color: white !important;
  border-radius: 10px !important;
  border-color: $blue-uch;
}

.question-button:hover {
  background-color: #00b7ff !important;
  border-color: #00b7ff !important;
}

.answer-selected {
  outline: 2px solid white;
  color: #00b7ff;
  border-radius: 5px;
}

.question-answer-enabled:hover {
  color: #00b7ff !important;
}

.previous-button {
  background-color: $blue-uch !important;
}

.next-button {
  background-color: $blue-uch !important;
}

.proceed-button {
  background-color: $blue-uch !important;
}

#select-final-img {
  width: 10rem;
  display: inline-block;
}

/* 03: Review */
#review-section {
  background-color: rgba(255, 255, 255, 0.85);
}

.review-answer {
  background-color: $blue-uch !important;
  margin-left: 7rem;
  margin-right: 7rem;
  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.review-buttons-container {
  margin-left: 7rem !important;
  margin-right: 7rem !important;
  @media screen and (max-width: 768px) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.review-buttons {
  border-radius: 10px !important;
}

.review-buttons:hover {
  background-color: #00b7ff !important;
}

.right-button-column {
  justify-content: flex-end;
  padding-right: 0 !important;
  @media screen and (max-width: 768px) {
    justify-content: center;
    padding-right: 0.75rem !important;
  }
}

.left-button-column {
  justify-content: flex-start;
  padding-left: 0 !important;
  @media screen and (max-width: 768px) {
    justify-content: center;
    padding-left: 0.75rem !important;
  }
}

#vote-fingerprint-anchor::before {
  word-break: normal;
}

#audit-button {
  background-color: rgba(0, 76, 148, 0.15);
  color: $blue-uch !important;
}

.question-audit {
}

.question-audit-icon {
  cursor: help;
}

#proceed_button {
  background-color: $blue-uch !important;
  border-color: $blue-uch !important;
  color: white !important;
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
  @media screen and (max-width: 768px) {
    margin-bottom: 3rem;
  }
}

.buttons-question {
  padding-bottom: 0;
  margin-left: 7rem !important;
  margin-right: 7rem !important;
  @media screen and (max-width: 768px) {
    padding-bottom: 3rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

#proceed_button:hover {
  background-color: #00b7ff !important;
  border-color: #00b7ff !important;
}

#review-final-img {
  width: 15rem;
  margin-left: auto;
  margin-right: auto;
}

#proceed-to-audit {
  float: right;
}

/* Encrypting */
#encrypting-section {
  background-color: rgba(255, 255, 255, 0.85);
  height: 45rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#encrypting-top-wrapper {
  align-self: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.encrypting-img-wrapper {
  align-self: auto;
  margin-top: auto;
}

#encrypting-final-img {
  width: 25vw;
}

/* 04: Audit */
#audit-section {
  background-color: rgba(255, 255, 255, 0.8);
  min-height: 50rem;
}

#audit-vote-tab {
  background-color: $blue-uch;
  color: white;
  font-size: 1.2rem;
}

#audit-vote-wrapper {
  margin-right: 1rem;
}

#important-tab {
  background-color: #0095d4;
  color: white;
  font-size: 1.2rem;
}

#pane-1 {
  border: 15px solid $blue-uch;
  border-top-left-radius: 0;
}

.audit-trail-box {
  text-align: left;
  word-break: break-all;
  max-height: 30rem;
  overflow-y: scroll;
}

#pane-2 {
  border: 15px solid #0095d4;
  border-top-left-radius: 0;
}

.important-box {
  text-align: left;
  min-height: 30rem;
}

#back-to-vote {
  border-radius: 10px !important;
  color: white;
  background-color: $blue-uch !important;
}

#send-audited-vote {
  border-radius: 10px !important;
  color: white;
  background-color: $green-uch !important;
}

#audit-final-img {
  width: 10rem;
  margin-left: auto;
  margin-right: auto;
}

.button-copy-audited {
  background-color: $blue-uch !important;
  color: white !important;
}

/* 05: Send */
#send-section {
  background-color: rgba(255, 255, 255, 0.85);
  padding-bottom: 18rem;
}

.send-text {
  line-height: 1 !important;
}

#vote-code {
  background-color: #00b7ff;
  margin-left: 7rem;
  margin-right: 7rem;
  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.progress-step {
  padding-top: 28% !important;
}

#back-button {
  background-color: $green-uch !important;
  color: white !important;
  border-radius: 10px !important;
}

.send-img-wrapper {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

#send-final-img {
  width: 20rem;
  display: inline-block;

}

/* Footer & Bottom */
$footer-background-color: $blue-uch;
$footer-padding: 0.5rem;
$footer-color: white;

.footer-container {
  word-wrap: break-word;
}

.bottom-hero {
}
